
body {
	background-color: #ededed;
}

.red{
	color: $colorRed;
}

%middleImg {

	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	img {
		display: inline-block;
		vertical-align: middle;
		max-width: 100%;
		max-height: 100%;
	}
}


%tabLink {
	height: 30px;
	vertical-align: middle;
	display: inline-block;
	line-height: 30px;
	padding: 0 20px;
	@include border-radius(5px);
	cursor: pointer;
	@include user-select();

	&:hover {
		color: #000;
	}

	&.is-active {
		background: $colorYellow;
		color: #000;
	}
}



%title{
	font-size: 36px;
	color: #000;
	font-family: 'helveticaneuecyr', sans-serif;
	font-weight: 600;

}


%roundBtn{
	padding: 0;
	width: 70px;
	height: 70px;
	@include border-radius(50%);
	background: $colorYellow;
	line-height: 70px;
	text-align: center;
	cursor: pointer;
	position: relative;

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		background-color: #000;
		@include transform(rotate(45deg));
	}

	&:before {
		width: 30px;
		height: 4px;
		margin-left: -15px;
		margin-top: -2px;
	}

	&:after {
		width: 4px;
		height: 30px;
		margin-left: -2px;
		margin-top: -15px;
	}

	&:hover {
		background-color: $colorYellowHover;
	}
}



.w{
	&_1{
		width: 100%;
	}

	&_1-2{
		width: (100% / 2);
	}

	&_1-4{
		width: (100% / 4);
	}
	&_3-4{
		width: (3 * 100% / 4);
	}

	&_1-6{
		width: (100% / 6);
	}

	&_2-6{
		width: (2 * 100% / 6);
	}

	&_5-6{
		width: (5 * 100% / 6);
	}


	&_1-3{
		width: (100% / 3);
	}
	&_2-3{
		width: (2 * 100% / 3);
	}

	&_1-5{
		width: (100% / 5);
	}
	&_2-5{
		width: (2 * 100% / 5);
	}
	&_3-5{
		width: (3 * 100% / 5);
	}


	&_1-8{
		width: (100% / 8);
	}
	&_1-8_{
		width: (1.5 * 100% / 8);
	}

	&_4-12{
		width: (4 * 100% / 12);
	}
	&_5-12{
		width: (5 * 100% / 12);
	}

	&_1-7{
		width: (100% / 7);
	}

	&_2-7{
		width: (2 *100% / 7);
	}

	&_3-7{
		width: (3 * 100% / 7);
	}

	&_4-7{
		width: (4 * 100% / 7);
	}

	&_5-7{
		width: (5 * 100% / 7);
	}

	&_6-7{
		width: (6 * 100% / 7);
	}
}

.wrapper  {
	position: relative;
	height: auto;
	overflow: hidden;
	@include box-sizing();
	padding-top:$headerHeight;

	.f-shadow &{
		padding-top:0;
	}
}

.website {
	position: relative;
}

.btn {
	font-size: 30px;
	text-align: center;
	color: #000;
	background-color: $colorYellow;
	display: inline-block;
	@include border-radius(100px);
	cursor: pointer;
	text-decoration: none;
	padding: 24px 25px 22px;
	@include user-select();
	@include box-sizing();
	font-weight: 600;

	&_grey {
		background-color: #ededed;
	}

	&:hover {
		background-color: $colorYellowHover;
	}

	&_more{
		width: 44px;
		height: 44px;
		border: none;
		padding: 0;
		position: relative;
		vertical-align: middle;
		display: inline-block;

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			background-color: #fff;
		}

		&:before {
			width: 20px;
			height: 2px;
			margin-left: -10px;
			margin-top: -1px;
		}

		&:after {
			width: 2px;
			height: 20px;
			margin-left: -1px;
			margin-top: -10px;
		}
	}

	&_white{
		background-color: #fff;

		&:hover {
			background-color: #f0f0f0;
		}
	}
}


nobr{
	white-space: nowrap;
}



.fill{

	&__inner{
		@include border-radius(5px);
		background-color: #fff;
		padding: 20px 15px;
		position: relative;
	}
}


.title {

	@extend %title;
	padding: 0 20px;
	margin: 25px 0;

}

.label{
	font-size: 15px;
	height: 40px;
	line-height: 40px;
	display: inline-block;
	@include border-radius(100px);
	color: #fff;
	padding: 0 23px;
	margin: 0 10px 15px 0;

	&_hit{
		background: $colorGreen;
	}
	&_new{
		background: $colorBlue;
	}
	&_recom{
		background: $colorRed;
	}
	&_sale{
		background: $colorYellow;
		color: #000;
	}
}



.fill,
.poster,
.legend,
.about,
.benefits,
.catalog,
.cards,
.material,
.textual{
	margin: 10px 0;


}


.fill,
.material,
.textual,
.benefits,
.delivery,
.product,
//.catalog__item,
.benefits__item,
.cardslider__item,
.about,
.cards__block,
.cards_fill,
.cards__item_goods,
.cards__item_announce,
//.poster__slider,
.poster__goods,
.legend__item,
//.order__item-goods,
//.order__item-info,
.order__legend-item,
.order__add,
//.form__field
{
	padding: 20px;


}






.poster {


	&__slider {

		.slick {

			&-list {
				-webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
			}

			&-arrow {
				position: absolute;
				display: block;
				@include border-radius(50%);
				background: #fff no-repeat 50% 50%;
				background-size: cover;
				width: 70px;
				height: 70px;
				top: 50%;
				margin-top: -35px;
				z-index: 10;
				color: transparent;
				cursor: pointer;
				@include box-shadow(0 2px 3px rgba(0, 0, 0, .3));

				&:active {
					@include box-shadow(0 0 0 rgba(0, 0, 0, .3));
				}

				svg {
					width: 100%;
					height: 100%;

					path {
						fill: #000;
					}
				}

				&:hover {
					svg {
						width: 100%;
						height: 100%;

						path {
							fill: $colorYellow;
						}
					}
				}
			}

			&-prev {
				left: 20px;
			}
			&-next {
				right: 20px;
			}

			&-dots {
				position: absolute;
				bottom: 30px;
				right: 20px;
				left: 20px;
				text-align: center;

				li {
					display: inline-block;
					margin-left: 10px;

					button {
						display: block;
						width: 15px;
						height: 15px;
						@include border-radius(50%);
						background: #fff;
						color: transparent;
						cursor: pointer;
					}

					&.slick-active {

						button {
							background: $colorYellow;
							@include box-shadow(0 2px 3px rgba(0, 0, 0, .3));
						}
					}
				}

			}
		}
	}

	&__item {
		font-family: 'helveticaneuecyr', sans-serif;
		color: #fff;
		height: 500px;
		position: relative;

		&-img {
			width: 100%;
			height: 100%;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: cover;
		}

		&-info {
			height: 100%;
			position: absolute;
			width: 100%;
			top:0;
			left: 0;
			font-size: 0;
			background-color: rgba(#22548c, 0.5);
			text-align: center;
			padding-top: 50px;

			@include box-sizing();

			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				height: 100%;
			}
		}

		&-inner {
			display: inline-block;
			vertical-align: top;
			padding: 0 9.3%;
			@include box-sizing();
			max-width: 100%;


		}

		&-title {
			font-size: 48px;
			font-weight: 500;
			margin-bottom: 30px;


		}

		&-text {
			font-size: 32px;
			font-weight: 400;
			margin-bottom: 25px;


		}

		&-more {
		}
	}

	&__goods {
		margin-left: 3 * (100% / 4);
		width: 1 * (100% / 4);
		@include box-sizing();
		height: 420px;
	}



	&_wide{

		.poster__slider{
			width: 100%;
			float: none;
		}
	}
}



.brand{

	background: #fff;
	color: #000;
	margin-bottom: 20px;

	&__logo{
		display: block;
		height: 130px;

		img{
			height: 100%;
		}
	}


	&__menu{
		display: block;
		font-size: 40px;
		padding: 0 20px;
		font-weight: 600;


		&-item{
			display: block;
			position: relative;
			padding: 30px 20px;
			border-top: 1px solid #ededed;

			&:after{
				content: '';
				display: block;
				background: url("/img/bc-arrow.svg") no-repeat 50% 50%;
				background-size: contain;
				width: 25px;
				height: 40px;
				position: absolute;
				right: 40px;
				top: 50%;
				margin-top: -20px;
			}

			span{
				display: inline-block;
				padding-bottom: 8px;
				border-bottom: 4px solid #ededed;
			}
		}
	}
}


.legend {

	font-size: 0;

	&__slider {
		position: relative;

		.slick {

			&-list {
				-webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
			}

			&-arrow {
				position: absolute;
				display: block;
				@include border-radius(50%);
				background: #fff no-repeat 50% 50%;
				background-size: cover;
				width: 70px;
				height: 70px;
				bottom: 40px;
				z-index: 10;
				color: transparent;
				cursor: pointer;
				@include box-shadow(0 2px 3px rgba(0, 0, 0, .3));

				&:active {
					@include box-shadow(0 0 0 rgba(0, 0, 0, .3));
				}

				svg {
					width: 100%;
					height: 100%;

					path {
						fill: #000;
					}
				}

				&:hover {
					svg {
						width: 100%;
						height: 100%;

						path {
							fill: $colorYellow;
						}
					}
				}
			}

			&-prev {
				left: 40px;
			}
			&-next {
				right: 40px;
			}

			&-dots {
				position: absolute;
				bottom: 60px;
				right: 20px;
				left: 20px;
				text-align: center;

				li {
					display: inline-block;
					margin-left: 10px;

					button {
						display: block;
						width: 15px;
						height: 15px;
						@include border-radius(50%);
						background: #fff;
						color: transparent;
						cursor: pointer;
						border: 2px solid #d3d3d3;
					}

					&.slick-active {

						button {
							background: $colorYellow;
							border-color: $colorYellow;
						}
					}
				}

			}
		}
	}

	&__item {

		@include box-sizing();
		display: block;
		font-size: 26px;

		&-inner {
			background: #fff;
			@include border-radius(5px);
			height: 380px;
			position: relative;
			@include box-sizing();
			overflow: hidden;
			padding: 45px 20px 0;
		}

		&-ico {
			text-align: center;
			font-size: 0;
			float: right;
			margin-right: 0;

			@extend %middleImg;
			height: 150px;
			//width: 200px;

			img{
				height: 100%;
			}

			& ~ .legend__item-info{
				float: left;
				width: calc(100% - 250px);
			}
		}

		&-info {
		}

		&-title {
			font-size: 36px;
			font-family: 'helveticaneuecyr', sans-serif;
			font-weight: 500;
			margin-bottom: 5px;
			color: #000;

		}

		&-text {
			font-size: 26px;

		}

	}
}

.breadcrumbs {
	padding: 0 20px;
	margin: 40px 0;
	font-size: 40px;

	a {

	}

	span {
		color: #000;
	}

	&__item {
		display: inline-block;
		vertical-align: middle;
		margin-right: 30px;

		&:before {
			content: '';
			display: inline-block;
			margin-right: 20px;
			width: 17px;
			height: 29px;
			background: url("/img/bc-arrow_m.svg") no-repeat 50% 50%;
			background-size: contain;
			top: 3px;
			left: 12px;
		}
	}
}


.about{

	&__inner{
		background: #fff;
		@include border-radius(5px);
		padding: 20px;
		overflow: hidden;
	}

	&__title{
		@extend %title;

		margin-bottom: 10px;
		padding: 0 10px;

	}

	&__text{
		float: left;
		width: 75%;
		@include box-sizing();
		padding: 0 10px;
	}

	&__image{
		float: left;
		width: 25%;
		@include box-sizing();
		padding: 0 10px;
		text-align: center;

		img{
			max-width: 100%;
		}
	}
}


.benefits{

	&__inner{
		background: #fff;
		@include border-radius(5px);
		padding: 15px;
	}

	&__title{
		@extend %title;

		margin-bottom: 15px;
		padding: 0 10px;

	}

	&__list{
		font-size: 0;
	}

	&__item{
		font-size: 13px;
		color: #000;
		display: inline-block;
		vertical-align: top;
		width: 25%;
		@include box-sizing();



		&-inner{
			@include border-radius(5px);
			border: 1px solid #d3d3d3;
			padding: 5px;
			height: 90px;
		}

		&-img{
			height: 100%;
			width: 75px;
			float: left;
			@extend %middleImg;
		}

		&-text{
			margin-left: 80px;
			height: 100%;
			overflow: hidden;
			@include box-sizing();
			padding: 5px 0;

			b{
				font-size: 18px;
				font-family: 'helveticaneuecyr', sans-serif;
				font-weight: 600;

			}
		}
	}
}


.catalog {


	&__title {
		@extend %title;

		margin: 40px 20px;

	}

	&__list {
		font-size: 0;
		position: relative;
	}

	&__item {
		display: block;
		vertical-align: top;
		text-align: center;
		@include box-sizing();
		margin: 20px;

		font-size: 0;

		&:hover {
			.catalog__item-inner {
				border-color: $colorYellow;
			}
		}

		&-inner {
			background: #fff;
			@include box-sizing();
			@include border-radius(5px);
			position: relative;
			border: 1px solid transparent;
		}

		&-logo {
			height: 270px;
			border-bottom: 1px solid #f5f4f2;

			@extend %middleImg;
		}

		&-name {
			height: 130px;

			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				height: 100%;
			}

			span {
				display: inline-block;
				vertical-align: middle;
				max-width: 100%;
				max-height: 100%;
				color: #000;
				font-family: 'helveticaneuecyr', sans-serif;
				font-weight: 500;

				font-size: 38px;

			}
		}
	}
}

.cards {

	&__block {
		height: 410px;
	}

	&__title {
		@extend %title;

		//padding: 0 7.5%;
		padding: 0 200px 0 20px;

		a {
			&:hover {
				text-decoration: underline;
			}
		}


		span {
			color: #7d7d7d;
			//font-size: 21px;
			margin-left: 10px;
		}
	}

	&__slider {

	}

	&__item {

		&_goods {

			display: block;
			@include box-sizing();
			font-size: 24px;


			.cards__item {

				&-inner {
					background: #fff;
					@include box-sizing();
					@include border-radius(5px);
					//height: 352px;
					//padding: 17px 7.5%;
					padding: 10px 20px 30px;
					position: relative;
					border: 1px solid transparent;
				}

				&-label {
					position: absolute;
					top: 12px;
					//left: 7.5%;
					left: 20px;

				}

				&-firm {
					text-align: left;
					//margin-bottom: 20px;
					font-size: 20px;

					a {
						color: inherit;
					}
				}

				&-img {
					height: 340px;
					margin-bottom: 18px;
					text-align: center;

					@extend %middleImg;
				}

				&-name {
					display: block;
					color: #000;
					text-decoration: none;
					overflow: hidden;
					//height: 30px;
					margin-bottom: 30px;
					font-size: 24px;

					&:hover {
						text-decoration: underline;
					}
				}

				&-price {

					font-family: 'helveticaneuecyr', sans-serif;
					display: inline-block;
					margin-right: 30px;
					vertical-align: bottom;

					&-old {
						display: block;
						font-weight: 300;
						margin-right: 10px;
						text-decoration: line-through;
						font-size: 24px;
					}

					&-new {
						display: inline-block;
						font-weight: 600;
						font-size: 36px;
						color: #000;

					}

					&-measure {
						display: inline-block;
						font-weight: 400;
						font-size: 20px;
						color: #000;
					}
				}

				&-cart {
					display: inline-block;
					vertical-align: bottom;
					width: 320px;
					height: 75px;
					padding: 0;
					line-height: 75px;
					@include box-sizing();
					@include border-radius(100px);
					background: $colorYellow;
					cursor: pointer;
					font-size: 24px;
					font-weight: 600;

					&:hover {
						background-color: $colorYellowHover;
					}
				}
			}
		}

		&_announce {

			width: 25%;
			@include box-sizing();
			display: inline-block;
			font-size: 22px;

			vertical-align: top;
			color: #000;


			.cards__item {

				&-inner {
					background: #fff;
					@include border-radius(5px);
					min-height: 400px;
					//padding: 35px 7.5%;
					padding: 35px 20px;
					position: relative;
					@include box-sizing();
				}

				&-charter {
					margin-bottom: 15px;
					position: relative;
					z-index: 1;

					a {
						color: #7d7d7d;
						text-decoration: none;

						&:hover {
							color: $colorYellow;
						}
					}
				}

				&-img {
					position: absolute;
					bottom: 0;
					top: 30px;
					right: 0;
					width: 100%;
					z-index: 0;
					@include user-select();
					text-align: right;
					font-size: 0;

					img {
						height: 100%;
						display: inline-block;
						@include border-radius(0 0 5px 5px);
					}
				}

				&-title {
					font-size: 36px;
					font-weight: 600;
					margin-bottom: 10px;
					position: relative;
					z-index: 1;
					margin-right: 30%;

					a {
						color: inherit;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}

				&-text {
					position: relative;
					color: $colorGrey;
					z-index: 1;
					margin-right: 30%;
				}

				&-more {
					position: absolute;
					bottom: 50px;
					//left: 7.5%;
					left: 20px;
					z-index: 1;

					.btn {
						min-width: 80px;
					}
				}

			}

			&.cards__item_advice {
				color: #fff;

				.cards__item-inner {
					background-color: #e2c54a;
				}

				.cards__item-charter {
					a {
						color: #fff;

						&:hover {
							color: #fff;
							text-decoration: underline;
						}
					}
				}

				.btn {
					background-color: #fff;
				}
			}

			&.cards__item_adv {

				.cards__item-title {
					font-size: 16px;
					margin-bottom: 35px;
				}

				.cards__item-price {
					@extend %title;

					color: $colorYellow;

					position: relative;
					z-index: 1;

				}
			}

		}

	}



	&__list {
		//font-size: 0;
		position: relative;

		.slick {

			&-slider {
				position: static;
			}

			&-list {
				@include border-radius(5px);
				-webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
			}

			&-arrow {
				position: absolute;
				display: block;
				@include border-radius(50%);
				width: 70px;
				height: 70px;
				top: 0;
				z-index: 10;
				color: transparent;
				cursor: pointer;
				@include box-shadow(0 2px 10px rgba(0, 0, 0, .3));

				svg {
					width: 100%;
					height: 100%;

					path {
						fill: #000;
					}
				}

				&:hover {

					border-color: $colorYellow;
					background-color: $colorYellow;

					svg {
						width: 100%;
						height: 100%;

						path {
							//fill: $colorYellow;
						}
					}
				}
			}

			&-prev {
				right: 40px;
			}
			&-next {
				right: 0;
			}
		}

		&_small {

			.cards {

				&__slider{

				}

				&__item {
					padding: 0;

					&-inner {
						border-color: #d3d3d3;
						margin: 5px;
						padding: 10px;
						height: 115px;
						text-align: left;
					}

					&-img {
						float: left;
						width: 27%;
						height: 95px;
						margin-bottom: 0;
					}

					&-name {
						margin-left: 30%;
						margin-top: 10px;
						margin-bottom: 25px;
						padding-right: 35px;
					}

					&-price {
						margin-left: 30%;

						&-old{
							position: absolute;
							bottom: 37px;
							font-size: 14px;
						}
					}

					&-link{
						width: 30px;
						height: 30px;
						background-size: 100%;
						left: auto;
						bottom:auto;
						right: 10px;
						top:15px;
					}

					&-cart{
						width: 30px;
						height: 30px;
						background-size: 100%;
						right: 10px;
						bottom:15px;
					}


					&:hover {

						.cards__item-inner {
							border-color: $colorYellow;
						}
					}
				}
			}
		}

		&_middle {

			background: #fff;
			@include border-radius(5px);
			@include box-sizing();
			//height: 410px;
			padding: 23px 0 0;
			margin: 20px;

			.cards {
				&__item {
					width: 100%;
					padding: 0;
				}

				&__title{
					height: 85px;
				}
			}

			.slick {
				&-arrow {
					top: 23px;
				}
				&-prev {
					//right: calc(7.5% + 40px);
					right: 120px;
				}
				&-next {
					//right: 7.5%;
					right: 20px;
				}
			}
		}

		&_large {
			.cards {
				&__item {

					&:hover {

						.cards__item-inner {
							border-color: $colorYellow;
						}
					}
				}

				&__title {
					@extend %title;

					padding: 0 10px;
					margin-bottom: 10px;

				}
			}
		}
	}

	&_fill{

		.cards__list{
			@include border-radius(5px);
			background-color: #fff;
			padding: 20px 15px;
		}

		.cards__title{
			margin: 0 10px 10px;
			padding: 0;
		}

		.cards__item-inner{
			border: 1px solid #d3d3d3;
		}

		.slick {
			&-arrow {
				top: 23px;
			}
			&-prev {
				//right: calc(7.5% + 40px);
				right: calc(20px + 40px);
			}
			&-next {
				//right: 7.5%;
				right: 20px;
			}
		}
	}

}

.cardslider {
	//font-size: 0;

	&__item {
		font-size: 24px;
		//display: inline-block;
		//width: 25%;
		@include box-sizing();

	}
}

.material {


	&__inner {
		background: #fff;
		padding: 40px 20px 40px;
		@include border-radius(5px);
	}

	&__title {
		font-family: 'helveticaneuecyr', sans-serif;
		color: #000;
		font-weight: 500;
		font-size: 36px;
		margin-bottom: 20px;
	}

	&__more{
		text-align: center;
		margin: 20px 0 0;

		.btn{
			font-size: 24px;
			background: #ededed;
		}
	}

	&__view {
		margin-bottom: 25px;

		.material__title {
			display: block;
			vertical-align: middle;
		}

		&-item {
			height: 60px;
			vertical-align: middle;
			display: inline-block;
			line-height: 60px;
			padding: 0 40px;
			@include border-radius(5px);
			cursor: pointer;
			@include user-select();
			margin-right: -15px;
			border: 1px solid #d3d3d3;
			position: relative;
			min-width: 150px;
			text-align: center;



			&.is-active {
				background: $colorYellow;
				border-color: $colorYellow;
				color: #000;
				z-index: 1;
			}
		}
	}

	&__list {
	}

	&__item {

		display: inline-block;
		vertical-align: top;
		font-size: 0;
		@include border-radius(100px);
		padding: 3px;
		margin: 15px 10px 15px 0;
		white-space: nowrap;
		@include box-sizing();
		position: relative;
		@include user-select();

		&.is-active,
		&:hover {
			border-color: $colorYellow;
			background: $colorYellow!important;

			.material__item-img {
				border-color: $colorYellow;
			}
		}

		&-img {
			width: 50px;
			height: 50px;
			background: no-repeat 50% 50%;
			background-size: 105%;
			display: inline-block;
			@include border-radius(50%);
			vertical-align: middle;
			border: 1px solid #d3d3d3;
			//border: 1px solid transparent;
		}

		&-name {
			display: none;
			vertical-align: middle;
			font-size: 22px;
			color: #000;
		}

		&-label {
			display: none;
			top: -25px;
			right: -15px;
			position: absolute;

		}
	}

	&_text {

		.material {

			&__item {
				border: 1px solid #d3d3d3;
				background-color: #fff;

				&-img {
					width: 50px;
					height: 50px;
					display: inline-block;
				}

				&-name {
					display: inline-block;
					font-size: 22px;
					margin: 0 15px;
				}

				&-label {
					display: block;
				}
			}
		}
	}

	&_notext {

		.material {

			&__item {

				margin-top: 8px;
				margin-bottom: 8px;

				&-img {

				}

				&-name {
					display: none;
				}

				&-label {
				}
			}
		}
	}
}

.textual {

	&__title{
		@extend %title;

		margin-bottom: 20px;

	}

	&__inner {
		background: #fff;
		@include border-radius(5px);
		padding: 25px 20px;

		h3 {
			color: #000;
			font-size: 1.538em;
			font-family: 'helveticaneuecyr', sans-serif;
			font-weight: 500;
			margin: 0 0 25px;
		}

		a {
			&:hover{
				text-decoration: underline;
			}
		}

		p {
			margin: 15px 0;
		}

		ul {
			margin: 20px 0;
			li {
				margin-bottom: 20px;
				padding-left: 15px;
				position: relative;

				&:before {
					content: '';
					width: 6px;
					height: 6px;
					@include border-radius(50%);
					background: #b1b1b1;
					display: block;
					position: absolute;
					top: 3px;
					left: 0;
				}
			}
		}

		& > *:first-child {
			margin-top: 0;
		}

		& > *:last-child {
			margin-bottom: 0;
		}
	}
}

.order {

	&__title {

		@extend %title;

		padding: 0 20px;
		margin-bottom: 10px;

	}

	&__list {
		font-size: 0;
		margin-bottom: 30px;
		position: relative;
	}

	&__remove{
		margin: -4px 20px 0 auto;
		position: absolute;
		right: 20px;
		top:-50px;

		&-btn{

			width: 44px;
			height: 44px;
			@include border-radius(50%);
			background: #fff;
			line-height: 44px;
			text-align: center;
			cursor: pointer;

			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				background-color: #a8a8a8;
				@include transform(rotate(45deg));
			}

			&:before {
				width: 20px;
				height: 2px;
				margin-left: -10px;
				margin-top: -1px;
			}

			&:after {
				width: 2px;
				height: 20px;
				margin-left: -1px;
				margin-top: -10px;
			}

			&:hover {
				background-color: $colorRed;

				&:before,
				&:after {
					background-color: #fff;
				}
			}
		}

		&-popup{
			width: 430px;
			padding: 30px 40px;
			@include box-sizing();
			@include border-radius(5px);
			background-color: #fff;
			position: absolute!important;
			right: 55px;
			top:0;
			display: none;

			&.f-active{
				display: block;
			}

			&-close{

				@extend %roundBtn;

				position: absolute;
				top:0;
				right: -55px;


			}

			&-title{
				@extend %title;
				margin-bottom: 15px;
			}

			&-text{
				font-size: 13px;
				margin-bottom: 35px;
				color: #000;

			}

			&-btn{
				.btn{
					margin-right: 10px;
				}
			}
		}
	}

	&__item {

		&-remove{
			width: 70px;
			height: 70px;
			@include border-radius(50%);
			background: #ededed;
			line-height: 70px;
			text-align: center;
			cursor: pointer;
			position: absolute;
			right: 20px;
			top: 20px;

			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				background-color: #a8a8a8;
				@include transform(rotate(45deg));
			}

			&:before {
				width: 30px;
				height: 4px;
				margin-left: -15px;
				margin-top: -2px;
			}

			&:after {
				width: 4px;
				height: 30px;
				margin-left: -2px;
				margin-top: -15px;
			}
		}

		&-inner {
			margin: 20px;
			background: #fff;
			@include border-radius(5px);
			font-size: 24px;
			padding: 0px 20px;
			@include box-sizing();
			position: relative;

		}

		&-goods {
			@include box-sizing();
			border-bottom: 1px solid $colorLight;
			padding: 40px 0;

			&-img {
				text-align: center;
				height: 250px;
				@extend %middleImg;
			}
			&-firm {
				color: $colorLight;
				margin-bottom: 5px;
				font-size: 36px;

			}
			&-name {
				color: #000;
				overflow: hidden;
				margin-bottom: 30px;
				font-weight: 600;

			}
			&-price {
				.price {
					&-old {
						font-family: 'helveticaneuecyr', sans-serif;
						font-weight: 300;
						color: $colorLight;
						text-decoration: line-through;
						margin-right: 10px;
						display: inline-block;
						font-size: 26px;
						vertical-align: baseline;

					}
					&-act {
						font-weight: 600;
						font-family: 'helveticaneuecyr', sans-serif;
						color: #000;
						font-size: 34px;
						margin-right: 10px;
						display: inline-block;
						vertical-align: baseline;

					}
					&-measure {
						font-size: 20px;
						color: #000;
						display: inline-block;
						vertical-align: baseline;
					}
				}
			}
		}

		&-info {
			@include box-sizing();
			padding: 40px 0;

			.order__item-inner {
				padding-top: 30px;

			}
		}
	}

	&__address {
		margin-bottom: 60px;
		//padding: 20px;

		&-form{

		}
	}

	&__contact {
		margin-bottom: 30px;

		&-form{
			.btn{
				height: 90px;
				line-height: 90px;
			}
		}
	}

	&__legend{

		&-item{

			@include box-sizing();
			display: block;

			&_result{
				.order__legend{

					&-inner{
						background: $colorYellow;
					}

					&-text{
						margin: 0 0 20px 0;
						font-size: 28px;
					}

					&-val{
						@extend %title;
						font-size: 56px;

						span{
							font-size: 38px;
						}
					}

				}
			}
		}

		&-inner{
			@include border-radius(5px);
			padding: 40px  20px;
			@include box-sizing();
		}

		&-ico{
			display: block;
			float: left;
			padding-top: 8px;

			img{
				display: inline-block;
				width: 44px;
				height: 44px;
				@include border-radius(50%);
			}
		}

		&-text{
			margin-left: 20%;
			height: 100%;
			color: #000;

			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				height: 100%;
			}

			span {
				display: inline-block;
				vertical-align: middle;
				max-width: 95%;
				max-height: 100%;
				font-family: 'helveticaneuecyr', sans-serif;
				font-weight: 500;

				font-size: 15px;

			}

		}
	}


	&__add{

		@include box-sizing();
		font-size: 26px;

		&-inner{
			background: #fff;
			@include border-radius(5px);
			padding: 20px 20px;
			//border: 1px solid $colorYellow;
			@include transition(background-color 0.3s ease);
		}

		&.lightning{
			.order__add-inner{
				background: $colorYellowHover;
			}
		}

		&-head{
			margin-bottom: 25px;
		}

		&-item{


			&:not(:first-child){
				border-top: 1px solid #ededed;
			}
			&-name{
				font-size: 36px;
				color: #000;
				font-weight: 600;
				padding: 30px 0;
				cursor: pointer;
				position: relative;

				&:after{
					content: '';
					display: block;
					background: url("/img/bc-arrow.svg") no-repeat 50% 50%;
					background-size: contain;
					width: 25px;
					height: 40px;
					position: absolute;
					right: 20px;
					top: 50%;
					margin-top: -20px;
				}
			}

			&-filter{
				display: none;
			}



			&.is-open{
				.order__add-item-filter{
					display: block;
				}

				.order__add-item-name{
					&:after{
						@include transform(rotate(90deg));
					}
				}
			}
		}

		&-title{

			@extend %title;

			padding: 20px 0 40px;
			display: inline-block;
			margin-left: 30px;
			vertical-align: middle;

		}

		&-category{
			display: inline-block;
			vertical-align: middle;

			&-item{
				@extend %tabLink;
			}
		}
	}


	&_bg{
		background: $colorYellow;
		margin: 10px -10px;
		padding: 20px 10px;

		.order__list{
			margin-bottom: 0;
		}

		.order__buy{
			text-align: right;
			margin-top: 10px;

			.btn{
				margin: 0 5px ;

			}
		}
	}


	&__btn{
		margin: 40px 20px;

		.btn{
			display: block;
			font-weight: 600;
			font-size: 34px;
			padding: 30px;

		}
	}

	&__more{
		margin: 40px 20px;
		text-align: center;

		.btn{
			background: #fff;
			display: inline-block;
			font-weight: 600;
			font-size: 34px;
			padding: 23px 130px;
		}
	}
}


.range{

	&__inner{
		background: #fff;
		margin: 20px;
		border-radius: 5px;
		padding: 0 20px;
	}

	&__item{
		&-name{
			font-size: 32px;
			color: #000;
			font-weight: 600;
			padding: 30px 0;
			cursor: pointer;
			position: relative;

			&:after{
				content: '';
				display: block;
				background: url("/img/bc-arrow.svg") no-repeat 50% 50%;
				background-size: contain;
				width: 25px;
				height: 40px;
				position: absolute;
				right: 20px;
				top: 50%;
				margin-top: -20px;
			}
		}

		&:not(:first-child){
			border-top: 1px solid #ededed;
		}

		&-filter{

			display: none;

			.cost__row{
				width: 100%;
				padding: 0;
			}

			.cost__col_name{
				font-size: 20px;
				padding: 0 20px;
				margin-bottom: 10px;
			}
		}

		&.is-open{
			.range__item-filter{
				display: block;
			}

			.range__item-name{
				&:after{
					@include transform(rotate(90deg));
				}
			}
		}
	}
}


.cost {
	font-size: 0;

	.btn{
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}

	&__row {
		font-size: 21px;
		@include box-sizing();
		padding: 0 10px;
		display: inline-block;
		vertical-align: top;
		width: 210px;
		margin-bottom: 30px;

		&_brend{
		}

		&_select{
		}

		&_materials{
			width: 290px;
		}

		&_result{
			display: block;
			width: 100%;
		}

		&_btn{
			display: block;
			width: 100%;

			.btn{
				display: block;
				padding: 30px;
				font-size: 34px;
			}
		}



	}
	&__col {
		white-space: nowrap;

		&_name {
			color: $colorGrey;
			margin-bottom: 15px;
			padding: 0 10px;
			font-size: 26px;
		}

		&_mid {
			color: #000;
			margin-bottom: 8px;
		}

		&_note {
			color: $colorLight;
			font-family: 'helveticaneuecyr', sans-serif;
			font-weight: 300;
		}
	}
	&__field {

		height: 70px;
		line-height: 68px;
		background-color: #fff;
		border: 1px solid #d3d3d3;
		@include box-sizing();
		@include border-radius(100px);
		padding: 0 30px;
		position: relative;
		@include user-select();
		cursor: pointer;

		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		&-val{
			display: inline;
		}

		.c-length,
		.c-material{
			width: 56px;
			height: 56px;
			display: inline-block;
			line-height: 56px;
			text-align: center;
			@include border-radius(50%);
			border: 1px solid #d3d3d3;
			vertical-align: middle;
			margin: 0 10px 0 -24px;
			position: relative;
			top: -2px;
			background: 50% 50% no-repeat;
			background-size: cover;
		}

		&:hover {
			border-color: $colorYellow;
		}

		&.is-error{
			border-color: $colorRed;
		}

		&.is-disable {
			border-color: transparent !important;
			padding: 0 10px;
			cursor: default;
			font-weight: 600;
			//font-size: 18px;

			.cost__field-handle{
				display: none;
			}
		}

		&.is-clean,
		&.is-multiple{

			.c-material{
				display: none;
			}

			.cost__field-handle{
				display: block;
			}
		}

		&.is-clean{
			.c-length{
				background-color: #d3d3d3;
			}

		}

		&.is-multiple{

			padding-right: 120px;
			.c-length{
				background-color: $colorYellow;
			}

			.cost__field-clear{
				display: block;
			}
			.cost__field-handle{
				right: 65px;
			}
		}




		&_select {
			padding-right: 70px;

			.cost__field-clear,
			.cost__field-handle {
				position: absolute;
				@include border-radius(50%);
				background-color: #ededed;
				width: 48px;
				height: 48px;
				top: 10px;
				right: 11px;

				&:before,
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					background-color: #a8a8a8;
				}

				&:before {
					width: 20px;
					height: 4px;
					margin-left: -10px;
					margin-top: -2px;
				}

				&:after {
					width: 4px;
					height: 20px;
					margin-left: -2px;
					margin-top: -10px;
				}

				&:hover{
					background-color: $colorYellow;
					&:before,
					&:after {
						background-color: #000;
					}
				}
			}

			.cost__field-clear{
				display: none;

				&:before,
				&:after {
					@include transform(rotate(45deg));
				}

				&:hover{
					background-color: $colorRed;
					&:before,
					&:after {
						background-color: #fff;
					}
				}
			}


		}

		&_search {
			input {
				background: none;
				width: 100%;
			}

		}

		&_input {
			input {
				background: none;
				width: 100%;
				//font-size: 18px;
				font-weight: 600;
			}

		}
		&_counter {
			padding: 0 70px;
			cursor: default;

			input {
				background: none;
				width: 100%;
				text-align: center;
				font-weight: 600;
				//font-size: 18px;

			}

			.cost__field-handle {
				position: absolute;
				width: 56px;
				height: 56px;
				top: 6px;
				cursor: pointer;

				&:hover {
					&:before,
					&:after {
						background-color: $colorYellow;
					}
				}

				&_dekr {
					left: 9px;

					&:before {
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						background-color: #a8a8a8;
						width: 20px;
						height: 4px;
						margin-left: -10px;
						margin-top: -2px;
					}
				}

				&_inkr {
					right: 9px;

					&:before,
					&:after {
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						background-color: #a8a8a8;
					}

					&:before {
						width: 20px;
						height: 4px;
						margin-left: -10px;
						margin-top: -2px;
					}

					&:after {
						width: 4px;
						height: 20px;
						margin-left: -2px;
						margin-top: -10px;
					}
				}
			}
		}
	}
	&__result {
		@extend %title;

		font-size: 52px;

		line-height: 44px;
		padding-left: 10px;

	}
	&__remove {
		width: 44px;
		height: 44px;
		@include border-radius(50%);
		background: #ededed;
		line-height: 44px;
		text-align: center;
		cursor: pointer;
		position: relative;
		margin: -4px 20px 0 auto;

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			background-color: #a8a8a8;
			@include transform(rotate(45deg));
		}

		&:before {
			width: 20px;
			height: 2px;
			margin-left: -10px;
			margin-top: -1px;
		}

		&:after {
			width: 2px;
			height: 20px;
			margin-left: -1px;
			margin-top: -10px;
		}

		&:hover {
			background-color: $colorRed;

			&:before,
			&:after {
				background-color: #fff;
			}
		}
	}
	&__container{
		position: absolute;
		display: none;
	}
	&_perse{

		.cost__col_name{
			color: $colorGrey;
		}
	}
	&__list{
		position: absolute;
		z-index: 110;
		top: 0;
		left: 0;
		@include user-select();

		.btn{
			padding: 22px 40px 20px;
			//position: absolute;
			min-width: 140px;
			margin: 0 15px 20px 0;
			font-size: 24px;
		}

		&-item{
			display: inline-block;
			font-size: 21px;
			min-width: 140px;
			padding: 0 30px;
			@include box-sizing();
			@include border-radius(100px);
			background: #fff;
			height: 70px;
			line-height: 70px;
			vertical-align: top;
			cursor: pointer;
			margin: 0 15px 20px 0;
			color: #000;
			position: relative;

			&:hover{
				background: $colorYellowHover;
			}

			&.is-active{
				background: $colorYellow;
			}

			&.is-disabled{
				cursor: default;
				background: #58799d!important;
				color: #1c4572;
				& > *{
					opacity: 0.15;
				}
			}
		}

		&-label{
			position: absolute;
			font-size: 11px;
			color: #fff;
			@include border-radius(100px);
			padding: 0 12px;

			height: 25px;
			line-height: 25px;

			right: -2px;
			top:-13px;

			&_hit{
				background-color: $colorGreen;
			}
			&_now{
				background-color: $colorBlue;
			}
		}

		&-material{
			width: 56px;
			height: 56px;
			background: no-repeat 50% 50%;
			background-size: 105%;
			@include border-radius(50%);
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
			margin-left: -24px;
			border: 1px solid #d3d3d3;
			position: relative;
			top: -2px;
		}

		&-legend{

			margin-top: 20px;

			&-item{

				color: #fff;
				display: inline-block;
				vertical-align: top;
				margin-right: 15px;

				i{
					width: 70px;
					height: 70px;
					display: inline-block;
					text-align: center;
					color: #000;
					font: inherit;
					line-height: 64px;
					@include border-radius(50%);
					vertical-align: middle;
					margin-right: 10px;
					position: relative;
					top: -2px;
				}

				&_1{
					i{
						background-color: #fff;
					}
				}

				&_2{
					i{
						background-color: #58799d;
					}
				}
			}
		}
	}
}


.product{

	.link{
		color: $colorBlue;

		&:hover{
			text-decoration: underline;
		}
	}

	&__inner{
		background: #fff;
		@include border-radius(5px);
	}



	&__label{
		float: left;
		padding: 20px;
	}

	&__view{

		padding: 20px;
		position: relative;

		display: block;
		vertical-align: top;

		&-img{
			text-align: center;
			img{
				max-width: 100%;
			}
		}
	}


	&__info{
		@include box-sizing();
		padding: 20px;

		display: block;
		vertical-align: top;

		font-size: 24px;



		&-group{

			&-name{
				border-top: 1px solid #d3d3d3;
				padding: 20px 0 23px;
				color: #000;
				font-size: 36px;
				font-weight: 600;
				cursor: pointer;

				span{
					display: inline-block;

					&:after{
						content: '';
						display: inline-block;
						border: 11px solid transparent;
						border-top-color: #000;
						border-bottom-width: 0px;
						vertical-align: middle;
						margin-left: 20px;
					}
				}
			}

			&-inner{
				padding: 20px 0;
				display: none;



			}


			&.is-open{

				.product__info-group-name{
					span{
						&:after{
							border: 11px solid transparent;
							border-bottom-color: #000;
							border-top-width: 0;
						}
					}
				}

				.product__info-group-inner{
					display: block;
				}
			}
		}

	}



	&__title{
		@extend %title;
		padding: 20px;

	}
	&__material{
		width: 50%;
		@include box-sizing();
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
		margin-left: -10px;

		&-item{
			@extend %tabLink;
		}
	}


	&__price{
		padding: 20px;
		line-height: 36px;

		&-val{
			@extend %title;
			display: inline-block;
			vertical-align: baseline;
			margin-right: 8px;

		}

		&-dim{
			display: inline-block;
			vertical-align: baseline;
		}

		&-available{
			float: right;
			font-size: 26px;

			&-title{
				display: inline-block;
				vertical-align: middle;
				margin-right: 5px;
			}

			&-view{
				display: inline-block;
				vertical-align: middle;
				margin-right: 5px;

				.available{

					display: inline-block;
					height: 16px;
					width: ( 22px * 3 );
					background: url("/img/available_w.svg") repeat-x 0 0;
					position: relative;

					&:before{
						content: '';
						display: block;
						background: url("/img/available.svg") repeat-x 0 0;
						position: absolute;
						top:0;
						left: 0;
						height: 100%;
					}

					&_low{
						&:before{
							width: 33%;
						}
					}

					&_mid{
						&:before{
							width: 66%;
						}
					}

					&_max{
						&:before{
							width: 100%;
						}
					}
				}
			}

			&-status{
				display: inline-block;
				vertical-align: middle;
				color: #000;
			}

		}

	}


	&__colors{
		margin: 15px 0;
		float: left;
		width: 100%;
		@include box-sizing();


		.material{
			margin: 0;
			padding: 0;

			&__title{
				font: inherit;
				color: inherit;
				padding: 8px 0;
			}

			&__view{
				margin-bottom: 0px;

				&-item{
					display: none;
				}
			}

			&__inner{
				padding: 0;
				background: transparent;
			}

			&__list{
				height: 60px;
				padding: 10px 50px 10px 0;
				position: relative;
				overflow: hidden;
			}

			.btn_more{
				margin: 10px 5px 10px 0;
				position: absolute;
				top:11px;
				right: 0;
			}

			&__btn{
				display: none;
			}
		}
	}

	&__feedbacks{
		@include box-sizing();
		padding: 20px;
		display: block;
		float: right;
		font-size: 24px;


		&-stars{
			font-size: 0;
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;

			.stars{
				background: url("/img/star_w.svg") repeat-x 0 0;
				background-size: contain;
				//width: 5 * 30px;
				//height: 20px;
				width: 267px;
				height: 34px;
				position: relative;
				margin-left: -5px;

				&:before{
					content: '';
					display: block;
					background: url("/img/star.svg") repeat-x 0 0;
					background-size: contain;
					position: absolute;
					top:0;
					left: 0;
					height: 100%;
				}

				&_0{
					&:before{
						width: 0;
					}
				}

				&_0-5{
					&:before{
						width: 10%;
					}
				}

				&_1{
					&:before{
						width: 20%;
					}
				}

				&_1-5{
					&:before{
						width: 30%;
					}
				}

				&_2{
					&:before{
						width: 40%;
					}
				}

				&_2-5{
					&:before{
						width: 50%;
					}
				}

				&_3{
					&:before{
						width: 60%;
					}
				}

				&_3-5{
					&:before{
						width: 70%;
					}
				}

				&_4{
					&:before{
						width: 80%;
					}
				}

				&_4-5{
					&:before{
						width: 90%;
					}
				}

				&_5{
					&:before{
						width: 100%;
					}
				}
			}
		}

		&-status{
			display: inline-block;
			vertical-align: middle;
		}
	}



	&__featurs{

		p{
			margin-bottom: 10px;

			span{
				color: #000;
			}
		}
	}


	&__delivery{
		vertical-align: top;


		&-title{
			font-weight: 600;
			color: #000;
			margin-bottom: 5px;
		}

		&-text{
			margin-bottom: 10px;
			font-size: 19px;
		}
	}

	&__pay{

		&-title{
			font-weight: 600;
			color: #000;
			margin-bottom: 15px;
		}

		&-list{
			overflow: hidden;
			position: relative;
			padding-right: 50px;
		}

		&-item{
			display: inline-block;
			vertical-align: middle;

			@extend %middleImg;

			&:not(:last-child){
				margin-right: 20px;
			}
		}

		&-btn{
			display: none;
			margin-top: 20px;
		}

		.btn_more{
			position: absolute;
			right: 0;
			top:0;
		}
	}

	&__add{
		@include box-sizing();
		margin-top: 60px;


		.cost{
			padding: 0 10px 50px;
		}
	}
}



.searcher{

	margin: 20px;

	&__inner{
		background: #fff;
		border-radius: 5px;
		padding: 20px;
	}

	.search__close{
		display: none;
	}

	.search{
		font-size: 30px;

		&__input{
			display: block;
			vertical-align: top;
			width: 100%;
			padding: 20px 0;
			@include box-sizing();


			input{
				height: 80px;
				line-height: 84px;
				padding: 0 40px;
				background-color: #fff;
				@include box-sizing();
				width: 100%;
				position: relative;
				@include border-radius(100px);
				z-index: 2;
				border: 1px solid #d3d3d3;


				.f-active & {
					border-color: transparent;
				}
			}
		}

		&__submit{
			display: block;
			vertical-align: top;
			padding: 20px 0px;
			@include box-sizing();


			.btn{
				display: block;
				padding: 30px;
				width: 100%;
			}
		}
	}
}



.delivery{

	font-size: 20px;

	&__inner{
		background: #fff;
		@include border-radius(10px);
		margin-bottom: 20px;
		overflow: hidden;

	}

	.delivery__inner{

		padding: 0px 20px 20px 20px;

		.delivery__title{
			padding: 0;
			margin: 60px 0 30px;
		}

		&_info{

			padding: 0px 240px 20px 20px;
		}

		&_dim{

			p{
				color: #000;
			}
		}

		&_mes{
			border: 1px solid $colorYellow;

			color: #000;
		}

		&_att{
			border: 1px solid $colorRed;

			* {
				color: $colorRed;
			}

		}
	}

	&__title{
		@extend %title;
		padding: 0 10px;
	}

	&__subtitle{
		font-size: 18px;
		color: #000;
		font-family: 'helveticaneuecyr', sans-serif;
		font-weight: 500;
		margin: 25px 0 15px;

	}

	b{
		font-size: 24px;
		color: #000;
		line-height: 1;
	}

	p {
		line-height: 1.5;
	}

	&__case{
		position: relative;
	}

	&__note{

		position: absolute;
		top: 0;
		right: -225px;
		width: 225px;
	}

	ul{
		margin: 10px 0;
		li{
			position: relative;
			padding-left: 38px;
			line-height: 1.5;


			&:before{
				content: '';
				width: 10px;
				height: 10px;
				background: $colorGrey;
				@include border-radius(50%);
				position: absolute;
				top:10px;
				left: 0;
			}
		}

		&.ul-measure{
			li{
				padding-left: 70px;
				&:before{
					content: '';
					border-radius: 0;
					top:0px;
					width: 50px;
					height: 30px;
					background: url("/img/delivery-mesure.svg") 50% 50% no-repeat;
					background-size: contain;
				}
			}
		}
	}



	&__ico{
		width: 120px;
		float: right;

		img{
			width: 100%;
		}

		& ~ .delivery__text{
			float: left;
			width: calc(100% - 150px);
		}
	}


}

.half{
	width: 50%;
	display: inline-block;
	vertical-align: top;
	padding: 0 5px;
	@include box-sizing();
	min-height: 1px;

	font-size: 13px;

}


.form{
	font-size: 0;

	&__field{
		display: inline-block;
		@include box-sizing();
		vertical-align: top;
		font-size: 21px;
		padding: 10px;


		&-inner{
			position: relative;
			@include border-radius(100px);
			background-color: #fff;
		}

		&-placeholder{
			position: absolute;
			top:0;
			left: 0;
			padding: 0 20px;
			height: 100%;
			line-height: 70px;
			z-index: 0;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 100%;
			@include box-sizing();
			font-size: 24px;

			span{
				font-size: 20px;
				position: absolute;
				color: $colorYellow;
				margin-left: 3px;
			}
		}
		&-message{
			position: absolute;
			top:0;
			right: 0;
			padding: 0 20px;
			height: 100%;
			line-height: 70px;
			z-index: 1;
			max-width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			@include box-sizing();
			display: none;
			font-size: 18px;
		}

		.input,
		input{
			height: 70px;
			line-height: 68px;
			padding: 0 20px;
			border: 1px solid transparent;
			@include box-sizing();
			width: 100%;
			position: relative;
			@include border-radius(100px);
			z-index: 2;
			font-size: 24px;

			.popup &,
			.fill &{
				border-color: #d3d3d3;

			}

			&:focus{
				& + {
					.form__field-placeholder{
						display: none;
					}
				}
			}
		}

		textarea {
			height: 260px;
			line-height: 1.2;
			padding: 15px 20px;
			border: 1px solid transparent;
			@include box-sizing();
			width: 100%;
			position: relative;
			@include border-radius(20px);
			z-index: 2;

			.popup &,
			.fill &{
				border-color: #d3d3d3;

			}

			&:focus{
				& + {
					.form__field-placeholder{
						display: none;
					}
				}
			}
		}

		.btn{
			width: 100%;

			.popup &,
			.fill &{
				border-color: transparent;
			}
		}


		&.error{
			color: $colorRed;
			input{
				border-color: $colorRed;
			}
			.form__field-message{
				display: block;
			}
		}

		&.success{
			input{
				border-color: $colorYellow;
			}
		}

		&.filled{
			.form__field-placeholder{
				display: none;
			}
		}


		&_file{

			&.is-progress{
				.input__progress{
					display: block;
				}

				.input__name,
				.input__stop,
				.input__remove{
					display: none;
				}
			}
		}


		.input{
			display: inline-block;
			vertical-align: middle;
			position: relative;
			white-space: nowrap;


			&__progress{
				display: none;
				height: 20px;
				border: 1px solid #d3d3d3;
				border-radius: 5px;
				position: absolute;
				top: 10px;
				left: 20px;
				width: 80%;
				background: #fff;

				&-line{
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					background: $colorYellow;
					border-radius: 3px;
				}

				&-stop{
					width: 20px;
					height: 20px;
					line-height: 40px;
					position: absolute;
					top: 0;
					right: -30px;
					cursor: pointer;

					&:before,
					&:after{
						content: '';
						height: 20px;
						width: 2px;
						margin-left: -1px;
						margin-top: -10px;
						background: #000;
						position: absolute;
						top: 50%;
						left: 50%;
					}

					&:before{
						@include transform(rotate(45deg));
					}
					&:after{
						@include transform(rotate(-45deg));
					}
				}

			}

			&__name{

				vertical-align: middle;
				display: inline-block;
				margin-right: 10px;
				max-width: 190px;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&__stop{
				width: 20px;
				height: 20px;
				line-height: 40px;
				vertical-align: middle;
				display: inline-block;
				position: relative;
				cursor: pointer;

				&:before,
				&:after{
					content: '';
					height: 20px;
					width: 2px;
					margin-left: -1px;
					margin-top: -10px;
					background: #000;
					position: absolute;
					top: 50%;
					left: 50%;
				}

				&:before{
					@include transform(rotate(45deg));
				}
				&:after{
					@include transform(rotate(-45deg));
				}
			}

			&__remove{
				font-size: 24px;
				background: #ebebeb;
				border-radius: 5px;
				height: 32px;
				line-height: 32px;
				position: absolute;
				top: 18px;
				right: 30px;
				cursor: pointer;
				padding: 0 10px;
			}

			&_file{

				position: relative;

				input{
					position: absolute;
					top: 0;
					left: 0;
					border: 0;
					background: 0;
					padding: 0;
					margin: 0;
					opacity: 0;
					width: 1px;
					height: 1px;
				}



			}
			&__message{
				vertical-align: middle;
				display: inline-block;
				color: $colorGrey;
				margin: 0 10px;
			}
		}

		&_add{

			.input{
				&_file{
					display: inline-block;
					width: 45%;
					cursor: pointer;
					text-align: center;
				}
			}

		}
	}
}


.comment{

	&__title{
		@extend %title;
		padding: 10px 10px 7px;

		span{
			color: $colorYellow;
			margin: 0 10px;
			display: inline-block;
		}

	}

	&__btn{
		position: absolute;
		top:25px;
		right: 20px;
	}

	hr{
		border: none;
		height: 1px;
		background: #f4f4f4;
		display: block;
		margin: 25px -15px;
	}

	&__item{

		overflow: hidden;
		padding: 20px 20px 40px 20px;

		&-name{
			font-size: 18px;
			color: #000;
			font-family: 'helveticaneuecyr', sans-serif;
			font-weight: 600;
			margin-bottom: 8px;

		}

		&-text{
			margin-bottom: 15px;
			color: #000;
			position: relative;
			line-height: 1.35;

			//&_comm{
			//	&:before{
			//		background-image: url("/img/ico-comment-main.svg");
			//	}
			//}
			//
			//&_advant{
			//	&:before{
			//		background-image: url("/img/ico-comment-advant.svg");
			//	}
			//}
			//
			//&_limit{
			//	&:before{
			//		background-image: url("/img/ico-comment-limit.svg");
			//	}
			//}
		}

		&-btn{
			float: left;
		}

		&-more{

			text-align: center;
			margin: 40px 0 50px;

			.btn{
				padding-right: 110px;
				background: #ededed url("/img/more.svg") no-repeat top 50% right 50px;
			}
		}


		&-rating{
			float: right;
		}

		&-like,
		&-dislike{
			width: 74px;
			height: 74px;
			display: inline-block;
			vertical-align: top;
			@include border-radius(50%);
			border: 1px solid #d3d3d3;
			position: relative;
			margin-right: 20px;
			margin-left: 20px;

			span{
				font-size: 18px;
				height: 38px;
				display: inline-block;
				@include border-radius(50%);
				color: #000;
				line-height: 38px;
				text-align: center;
				background: #d3d3d3;
				position: absolute;
				top:-9px;
				@include box-sizing();
				padding: 0 8px;
				min-width: 38px;
				right: -16px;
			}

		}

		&-like{
			background:  url("/img/ico-like.svg") no-repeat 50% 50%;
			background-size: contain;
			margin-right: 20px;

			span{
				background-color: $colorYellow;
			}
		}

		&-dislike{
			background: url("/img/ico-dislike.svg") no-repeat 50% 50%;
			background-size: contain;
		}

	}



	&__pager{
		padding: 0 5px 10px;

		.pager{
			padding: 0;
			margin: 0;
		}
	}
}


.pager{

	text-align: center;
	&_perse{

		padding: 0 10px;
		margin: 30px 0;
	}

	&__item{
		width: 70px;
		height: 70px;
		@include border-radius(50%);
		@include box-shadow(0 1px 3px rgba(0,0,0,.4));
		line-height: 70px;
		text-align: center;
		display: inline-block;
		vertical-align: top;
		font-size: 28px;
		font-weight: 700;
		color: #000;
		font-family: 'Roboto', sans-serif;
		margin-right: 25px;
		margin-bottom: 25px;
		background: #fff no-repeat 50% 50%;
		background-size: contain;

		&_dots{
			line-height: 70px;
			@include box-shadow(none);
			background-color: transparent!important;
		}
		
		&_prev{
			background-image: url("/img/ico-arrow-prev.svg");
		}
		&_next{
			background-image: url("/img/ico-arrow-next.svg");
			margin-right: 0;
		}

		&.is-active{
			background-color: $colorYellow!important;
			@include box-shadow(none);
		}

		&:hover{
			background-color: $colorYellowHover;
		}
	}
}







.contact{

	color: #000;
	position: relative;

	&__title{
		@extend %title;
		margin-bottom: 20px;
		padding: 20px;
	}

	&__item{
		display: inline-block;
		margin-bottom: 25px;
	}

	&__subtitle{
		font-size: 36px;
		color: #000;
		font-family: 'helveticaneuecyr', sans-serif;
		font-weight: 500;
		margin-bottom: 10px;

	}


	&__text{
		margin-bottom: 20px;
		color: $colorLight;
		font-size: 26px;
	}




	&__feedback{
		margin: 0 -10px;
	}


	&__map{
		background: #d3d3d3;
		height: 670px;
		margin: 20px;

		@include border-radius(0 5px 5px 0);
	}
}






[data-tab-targ]{
	display: none;

	&.is-active{
		display: block;
	}
}





.overlay{
	position: fixed;
	top:0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(#1f4c7e, 0.9);

	z-index: 100;
	display: none;


	.f-shadow &{
		display: block;
	}
}


.f-active{
	position: relative;
	z-index: 110;

	&.f-product-material{
		.material__item-img{

			.material_notext & {
				border-color: transparent;
			}
		}
		.material__view-item{
			display: inline-block;
		}
		.material__title{
			color: #fff;
		}
		.material__view-item{
			border: 1px solid #a8a8a8;
			color: #a8a8a8;
			margin-right: 10px;
			&:hover{
				color: #fff;
			}

			&.is-active{
				border-color: transparent;
				color: #000;
			}
		}

		.material__list{
			overflow: visible;
		}

		.material__item{
			border-color: transparent;
		}

		.btn_more{
			display: none;
		}

		.material__btn{
			display: block;
			margin-top: 20px;
		}
	}



	&.f-product-pay{

		.product__pay-list{
			overflow: visible;
		}

		.product__pay-title{
			color: #fff;
		}

		.btn_more{
			display: none;
		}

		.product__pay-btn{
			display: block;
		}
	}
}





.search{

	position: relative;
	font-size: 30px;

	&__input{
		display: inline-block;
		vertical-align: top;
		width: 50%;
		padding: 0 5px;
		@include box-sizing();


		input{
			height: 80px;
			line-height: 84px;
			padding: 0 40px;
			background-color: #fff;
			@include box-sizing();
			width: 100%;
			position: relative;
			@include border-radius(100px);
			z-index: 2;
			border: 1px solid #d3d3d3;


			.f-active & {
				border-color: transparent;
			}
		}
	}

	&__submit{
		display: block;
		vertical-align: top;
		padding: 20px 0;
		@include box-sizing();


		.btn{
			width: 100%;
		}
	}

	&__close{

		display: inline-block;
		vertical-align: top;
		margin: 0 5px;
		position: absolute;
		top: 0;
		right: 0;


		.btn{

			padding: 0;

			width: 80px;
			height: 80px;
			@include border-radius(50%);
			background: $colorYellow;
			line-height: 80px;
			text-align: center;
			cursor: pointer;
			position: relative;

			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				background-color: #000;
				@include transform(rotate(45deg));
			}

			&:before {
				width: 38px;
				height: 4px;
				margin-left: -19px;
				margin-top: -2px;
			}

			&:after {
				width: 4px;
				height: 38px;
				margin-left: -2px;
				margin-top: -19px;
			}

			&:hover {
				background-color: $colorYellowHover;


			}
		}


	}
}



.hint{
	display: inline-block;
	position: relative;
	z-index: 2;

	&__label{
		color: #000;
		cursor: pointer;
		width: 30px;
		text-align: center;
		height: 30px;
		border-radius: 50%;
		line-height: 30px;
		background: $colorYellow;

		&:hover{
			background: $colorYellowHover;
		}
	}

	&__close{
		position: absolute;
		width: 20px;
		height: 20px;
		top: 10px;
		right: 10px;
		cursor: pointer;

		&:before,
		&:after{
			content: '';
			height: 20px;
			width: 2px;
			margin-left: -1px;
			margin-top: -10px;
			background: $colorGreyLight;
			position: absolute;
			top: 50%;
			left: 50%;
		}

		&:before{
			@include transform(rotate(45deg));
		}
		&:after{
			@include transform(rotate(-45deg));
		}
	}

	&__text{
		padding: 40px 15px;
		position: absolute;
		display: none;
		background: #fff;
		bottom: 0px;
		left: 50px;
		text-align: left;
		border-radius: 3px;
		@include box-shadow(0 0 10px rgba(0,0,0,0.5));
	}

	&__inner{
		font-size: 18px;
		width: 300px;
		max-height: 280px;
		overflow: auto;
	}

	&.is-open{
		.hint__text{
			display: block;
		}
	}
}