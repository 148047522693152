$minWidth: 640px;
$asideWidth: calc((100% * 0.345) - 288.12px);
$headerHeight: 130px;
$footerHeight: 215px;


$colorYellow: #fbda52;
$colorYellowHover: #fde47d;
$colorBlue: #1a6f9d;
$colorRed: #de6c40;
$colorLight: #c0bfbe;
$colorGreen: #54943f;
$colorGrey: #a6a6a6;
$colorGreyLight: #b1b1b1;

$zAside: 20;

@import '../../bower_components/breakpoint-sass/stylesheets/_breakpoint';
@import 'sass/mixins';
@import 'sass/normalize';

@import 'sass/slick';
//@import 'sass/nouislider';

@import 'sass/main';
@import 'sass/aside';
@import 'sass/header';
@import 'sass/footer';
@import 'sass/popup';
@import 'sass/autocomplete';

html {
	min-width: $minWidth;
	font-family: 'Open Sans', sans-serif;
	color: #7d7d7d;
	font-size: 30px;
	height: 100%;
}

body {
	min-width: $minWidth;
	opacity: 1 !important;
	line-height: 1.2;
	height: 100%;

	&.show-menu{
		overflow: hidden;
		height: 100vh;
	}
}

.yellow{
	color: $colorYellow!important;
}

@import 'sass/fonts';