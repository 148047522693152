@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600&subset=cyrillic,cyrillic-ext');
//	font-family: 'Open Sans', sans-serif;
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&subset=cyrillic-ext');
// font-family: 'Roboto', sans-serif;

@font-face {
	font-family: 'helveticaneuecyr';
	src: url('/fonts/helveticaneuecyrthin/helveticaneuecyr-thin.eot');
	src: local('☺'),
	url('/fonts/helveticaneuecyrthin/helveticaneuecyr-thin.woff') format('woff'),
	url('/fonts/helveticaneuecyrthin/helveticaneuecyr-thin.ttf') format('truetype'),
	url('/fonts/helveticaneuecyrthin/helveticaneuecyr-thin.svg') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'helveticaneuecyr';
	src: url('/fonts/helveticaneuecyrroman/helveticaneuecyr-roman.eot');
	src: local('☺'),
	url('/fonts/helveticaneuecyrroman/helveticaneuecyr-roman.woff') format('woff'),
	url('/fonts/helveticaneuecyrroman/helveticaneuecyr-roman.ttf') format('truetype'),
	url('/fonts/helveticaneuecyrroman/helveticaneuecyr-roman.svg') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'helveticaneuecyr';
	src: url('/fonts/helveticaneuecyrmedium/helveticaneuecyr-medium.eot');
	src: local('☺'),
	url('/fonts/helveticaneuecyrmedium/helveticaneuecyr-medium.woff') format('woff'),
	url('/fonts/helveticaneuecyrmedium/helveticaneuecyr-medium.ttf') format('truetype'),
	url('/fonts/helveticaneuecyrmedium/helveticaneuecyr-medium.svg') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'helveticaneuecyr';
	src: url('/fonts/helveticaneuecyrbold/helveticaneuecyr-bold.eot');
	src: local('☺'),
	url('/fonts/helveticaneuecyrbold/helveticaneuecyr-bold.woff') format('woff'),
	url('/fonts/helveticaneuecyrbold/helveticaneuecyr-bold.ttf') format('truetype'),
	url('/fonts/helveticaneuecyrbold/helveticaneuecyr-bold.svg') format('svg');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'helveticaneuecyr';
	src: url('/fonts/helveticaneuecyrblack/helveticaneuecyr-black.eot');
	src: local('☺'),
	url('/fonts/helveticaneuecyrblack/helveticaneuecyr-black.woff') format('woff'),
	url('/fonts/helveticaneuecyrblack/helveticaneuecyr-black.ttf') format('truetype'),
	url('/fonts/helveticaneuecyrblack/helveticaneuecyr-black.svg') format('svg');
	font-weight: 900;
	font-style: normal;
}




