.footer{
	z-index: 1;
	position: relative;
	font-family: 'Roboto', sans-serif;

	&__menu {
		//border-bottom: 1px solid #f5f4f2;
		background: #fff;
		font-weight: 400;
		padding: 0 20px;

		&-item {
			@include box-sizing();
			padding: 40px 20px;
			display: block;
			font-size: 36px;
			position: relative;

			&:not(:first-child){
				border-top: 1px solid $colorGrey;
			}

			&:not(.footer__menu-item_phone){

				&:after{
					content: '';
					display: block;
					background: url("/img/bc-arrow.svg") no-repeat 50% 50%;
					background-size: contain;
					width: 25px;
					height: 40px;
					position: absolute;
					right: 40px;
					top: 50%;
					margin-top: -20px;
				}
			}

			&_phone{
				text-align: center;
				color: #000;

				span{
					text-decoration: underline;
				}
			}

			&:before {
				content: '';
				display: inline-block;
				height: 100%;
				vertical-align: middle;
			}

			span {
				vertical-align: middle;
				display: inline-block;
				max-width: 100%;
			}
		}

	}

	&__info {
		text-align: center;
		font-weight: 300;
		font-size: 43px;
		padding-bottom: 60px;

		&-copy,
		&-pay,
		&-soc,
		&-create {
			@include box-sizing();
			padding: 0 15px;
		}

		&-copy {
			margin: 60px 0;
		}

		&-pay {
			margin: 60px 0;

			&-item {
				display: inline-block;
				vertical-align: middle;
				height: 55px;
				max-width: 160px;
				margin: 0 15px;

				svg {
					height: 100%;
					width: auto;
					max-width: 100%;

					path {
						fill: $colorGrey;
					}
				}

				&:hover {

					svg {
						path {
							fill: $colorYellow;
						}
					}
				}
			}
		}

		&-soc {
			margin: 60px 0;

			&-item {
				display: inline-block;
				vertical-align: middle;
				width: 100px;
				height: 100px;

				margin: 0 10px;

				svg {
					width: 100%;
					height: 100%;
					path {
						fill: #a6a6a6;
					}
				}

				&:hover {

					svg {
						path {
							fill: $colorYellow;
						}
					}
				}
			}
		}

		&-create {
			.tdu{
				display: inline-block;
				border-bottom: 2px solid currentColor;
			}
		}
	}
}