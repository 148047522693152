.aside {
	width: 100%;
	background-color: #fff;
	position: fixed;
	font-size: 36px;

	top: -100vh;
	left: 0;
	@include box-shadow(2px 0 3px rgba(38, 38, 38, 0.2));
	z-index: $zAside;
	@include transition(top 0.3s ease);

	@include box-sizing();
	overflow: auto;
	max-height: calc( 100vh - #{$headerHeight});

	.show-menu &{
		top: $headerHeight!important;
	}


	&__contacts{
		padding: 20px;
		&-phone{
			font-size: 34px;
			color: #000;
			display: inline-block;
			width: 300px;
			font-weight: 600;
			vertical-align: middle;
		}

		&-mgr{
			width: 80px;
			height: 80px;
			display: inline-block;
			vertical-align: middle;
			border-radius: 50%;
			background: $colorYellow;
			margin-right: 5px;

			img{
				display: block;
				width: 100%;
				height: 100%;
			}
		}
	}

	&__inner {
		padding: 20px 0;
	}

	&__btn{
		.btn{
			display: block;
			padding: 30px;
			font-weight: 600;
		}
	}

	&__menu {
		padding: 0 20px;

		&-item {
			padding: 20px 0;

			&_sub {
				font-size: 42px;
				color: #000;
				font-family: helveticaneuecyr, sans-serif;
				font-weight: 500;
				cursor: pointer;

				span {
					font-size: 38px;
					font-family: 'Open Sans', sans-serif;
					color: #7d7d7d;
					font-weight: 400;
					padding-left: 10px;
				}
			}
		}

		&-link {

			&.active {
				color: $colorYellow;
				text-decoration: underline;
			}

			&:hover {
				color: $colorYellow;
				text-decoration: underline;
			}
		}
	}

	&__submenu {
		display: none;

		&.is-open {
			display: block;
		}

		& + * {
			padding-top: 25px;
		}
	}

	.footer__menu{
		padding-top: 40px;
		&-item{
			border-top: 1px solid $colorGrey;
			border-bottom: 1px solid $colorGrey;
			margin-bottom: -1px;
		}
	}


	.footer__info{
		text-align: left;
		padding: 20px;
	}
}