.header{
	height: $headerHeight;
	background-color: #fff;
	position: fixed;
	top: 0;
	width: 100%;
	left: 0;
	z-index: 20;

	.f-shadow &{
		position: static;
	}

	.inner {
		margin: 0 1.3%;
		text-align: right;
	}

	&__hendle{
		display: block;
		float: left;
		background: #ededed no-repeat 50% 50%;
		background-size: contain;
		@include border-radius(100px);
		height: 80px;
		width: 80px;
		position: relative;
		margin: 25px 0 0 20px;
		cursor: pointer;

		&:hover {
			background-color: $colorYellow;
		}

		i{
			width: 38px;
			height: 4px;
			position: absolute;
			display: block;
			left: 50%;
			top: 50%;
			margin-left: -19px;
			margin-top: -2px;
			background: #000;
			@include transition(all 0.3s ease);

			.show-menu &{
				width: 4px;
				margin-left: -2px;
			}

			&:before,
			&:after{
				content: '';
				width: 38px;
				height: 4px;
				position: absolute;
				display: block;
				left: 50%;
				margin-left: -19px;
				margin-top: -2px;
				background: #000;
				@include transform(rotate(0deg));
				@include transition(all 0.3s ease);
			}


			&:before{
				top: -9px;

				.show-menu &{
					top: 50%;
					@include transform(rotate(-45deg));
				}
			}

			&:after{
				top: 13px;

				.show-menu &{
					top: 50%;
					@include transform(rotate(-135deg));
				}
			}
		}
	}

	&__logo {
		color: #000;
		font-size: 72px;
		display: block;
		float: left;
		margin: 18px 0 0 40px;

	}

	&__note {
		float: left;
		padding: 30px 0 0;
	}

	&__contact {
		display: inline-block;
		vertical-align: middle;
		margin-right: 15px;

		.phone {
			display: inline-block;
			font-size: 21px;
			color: #000;
			font-family: 'helveticaneuecyr', sans-serif;
			margin-right: 10px;
		}
	}

	&__order {
		display: inline-block;
		vertical-align: middle;
		padding: 13px 0;
		margin-right: 20px;

		.btn {
			padding-right: 35px;
			padding-left: 35px;
		}
	}

	&__menu {
		display: inline-block;
		vertical-align: middle;
		font-size: 0;
		float: right;
		margin-right: 32px;
		margin-top: 25px;

		&-item {
			background: #ededed no-repeat 50% 50%;
			background-size: contain;
			@include border-radius(100px);
			height: 80px;
			width: 80px;
			display: inline-block;
			position: relative;

			&:hover {
				background-color: $colorYellow;
			}

			&_search {
				background-image: url("/img/ico-header-search.svg");
				margin-right: 30px;
			}
			&_calc {
				background-image: url("/img/ico-header-calc.svg");
				margin-right: 30px;
			}
			&_cart {
				background-image: url("/img/ico-header-cart.svg");
			}

			span {
				height: 40px;
				min-width: 40px;
				display: inline-block;
				top: -6px;
				right: -15px;
				position: absolute;
				@include border-radius(100px);
				background: $colorYellow;
				font-weight: 600;
				color: #000;
				text-align: center;
				line-height: 40px;
				padding: 0 5px;
				font-size: 18px;
				@include box-sizing();
			}
		}
	}


	&__search{
		display: none;

		position: absolute;
		top: 26px;
		//right: calc(1.3% + 50px);



		&.f-active{
			display: block;
			position: fixed;
		}



		.search__input{
			width: 520px;
			display: block;
		}
		.search__submit{

		}
		.search__close{

		}

		&-exemple{

			width: 600px;
			text-align: left;
			padding: 0 10px;
			margin-top: 20px;


			span{

				display: inline-block;
				vertical-align: top;
				white-space: nowrap;
				border: 1px solid #d3d3d3;
				color: #d3d3d3;
				@include border-radius(100px);
				height: 70px;
				line-height: 70px;
				padding: 0 30px;
				cursor: pointer;
				margin: 0 10px 30px 0;

				&:hover{
					color: $colorYellow;
					border-color: $colorYellow;
				}
			}
		}
	}
}
